<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Completed Marking</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Completed Marking
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <!--                  <a @click="createExamCandidate()" class="btn btn-primary font-weight-bolder font-size-sm">
                                      <span class="svg-icon svg-icon-md svg-icon-white"></span>
                                      Create New
                                    </a>&nbsp;-->

                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Enrolment key" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <button class="btn btn-primary">Search</button>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Marked Date</th>
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Exam name</th>
                  <th class="px-3">Marked Obtained </th>
                  <th class="px-3">Marked By</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr>
                    <td class="px-2">
                      <a class="font-weight-bolder d-block font-size-lg"
                      >2020-02-13</a>
                    </td>
                    <td>
                      <span class="font-weight-bolder d-block font-size-lg"
                      >854623325</span>
                    </td>

                    <td>
                     <span class="font-weight-bolder d-block font-size-lg"
                     >Accordion (2021+) (Comprehensive) Grade 2</span>
                    </td>
                    <td>
                     <span class=" font-weight-bolder d-block font-size-lg"
                     >75</span>
                    </td>
                    <td>
                     <span class=" font-weight-bolder d-block font-size-lg"
                     >Ven Diesel</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editCompletedGrading()" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit </span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>

                  </tr>
                  <tr>
                    <td class="px-2">
                      <a class=" font-weight-bolder d-block font-size-lg"
                      >2021-03-12</a>
                    </td>
                    <td>
                      <span class=" font-weight-bolder d-block font-size-lg"
                      >4579862656</span>
                    </td>

                    <td>
                     <span class=" font-weight-bolder d-block font-size-lg"
                     >Accordion (2021+) (Comprehensive) Grade 1</span>
                    </td>
                    <td>
                     <span class=" font-weight-bolder d-block font-size-lg"
                     >85</span>
                    </td>
                    <td>
                     <span class=" font-weight-bolder d-block font-size-lg"
                     >Paul Walker</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editCompletedGrading()" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit </span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>

                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    x
  </v-app>
</template>
<script>

export default {
  data() {
    return{
      dialog:false,
      exams:['Exams','Accordion (2021+) (Comprehensive) Associate Diploma',
        'Accordion (2021+) (Comprehensive) Grade 1',
        'Accordion (2021+) (Comprehensive) Grade 2',
        'Accordion (2021+) (Comprehensive) Grade 3',
        'Accordion (2021+) (Comprehensive) Grade 4',
      ],


    }
  },
  methods: {
    closeDialog(){
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
    },
    createExamCandidate(){
      this.resetForm();
      this.openDialog();
    },
    editCompletedGrading(){

    }


  }
}
</script>
